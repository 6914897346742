import axios from '@/utils/axios';

const storeProdutoCombo = async (data) => {
  let result = null;
  try {
    result = (await axios.post('/produto_combos/store', data)).data;
  } catch (error) {
    console.log("ERROR", error)
  }
  return result;
}
const getProdutoCombo = async (data) => {
  let result = null;
  try {
    result = (await axios.post('/produto_combos/get', data)).data;
  } catch (error) {
    console.log("ERROR", error)
  }
  return result;

}
const delProdutoCombo = async (id) => {
  let result = null;
  try {
    result = (await axios.post('/produto_combos/del', { id })).data;
  } catch (error) {
    console.log("ERROR", error)
  }
  return result;

}


export default {
  storeProdutoCombo,
  getProdutoCombo,
  delProdutoCombo

}