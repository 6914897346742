<template>
  <div>
    <BarraCrud :onNovo="onNovo" :botaoNovo="true" :onFiltrar="filtrar" />
    <div class="text-center p-5" v-if="loading">
      <b-spinner /><br />
      carregando...
    </div>
    <div class="" v-if="!loading">
      <div v-if="lista && lista.length<=0" class="text-center p-4 m-4">:( Nenhum combo de produtos encontrado</div>
      <b-table
      hover
        :items="lista"
        :fields="[
          { key: 'nome', label: 'Nome' },
          { key: 'valor', label: 'Valor', class: 'text-center' },
          { key: 'periodo', label: 'Periodo Validade' },
          { key: 'bts', label: '' },
        ]"
      >
        <template #cell(valor)="row">
          {{ row.item.valor | currency }}
        </template>
        <template #cell(periodo)="row">
          {{ row.item.validade_i | moment("DD/MM/YYY") }} -
          {{ row.item.validade_f | moment("DD/MM/YYY") }}
        </template>
        <template #cell(bts)="row">
          <b-btn variant="warning" class="mr-1" size="sm" @click="edit(row.item)">
            <i class="fa fa-edit mr-1" /> Editar</b-btn
          >
          <b-btn variant="danger" size="sm" @click="delCombo(row.item)">
            <i class="fa fa-trash mr-1" /> Excluir</b-btn
          >
        </template>
      </b-table>
    </div>
    <b-modal
      id="modal-edit-combo"
      hide-footer
      title="Cadastrar/Editar Combo de Produto"
      size="xl"
    >
      <div v-if="objEdit">
        <div class="row">
          <div class="col-12 col-sm-3">
            <label><b>Código</b></label>
            <b-input readonly v-model="objEdit.id" />
          </div>
          <div class="d-none d-sm-block col-sm-3"></div>
          <div class="col-12 col-sm-6">
            <label><b>Periodo Validade</b></label>
            <div class="d-flex d-gap-2">
              <b-input
                type="date"
                style="max-width: 50%"
                class="ml-2"
                v-model="objEdit.validade_i"
              />
              <b-input
                type="date"
                style="max-width: 50%"
                class="ml-2"
                v-model="objEdit.validade_f"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-8">
            <label><b>Nome</b></label>
            <b-input v-model="objEdit.nome" />
          </div>
          <div class="col-4 col-lg-4">
            <label><b>Valor de Venda</b></label>
            <money
              class="form-control w-100"
              v-bind="moneyMask"
              v-model="objEdit.valor"
            />
          </div>
          <div class="col-12">
            <hr />
          </div>
          <div class="col-12">
            <b>Produtos do Combo</b>
            <div v-if="objEdit && objEdit.produtos">
              <div
                class="prodGroup"
                v-for="prod in objEdit.produtos"
                :key="`prod_ed${prod.id}`"
              >
                <div class="row">
                  <div class="col-5">
                    <label>Produto</label>
                    <v-select
                      :options="produtosDisponiveis"
                      v-model="prod.produto"
                      label="nome"
                    />
                  </div>
                  <div class="col-5">
                    <div v-if="prod.produto && prod.produto.precos">
                      <label>Tipo / Tamanho</label>
                      <v-select
                        :options="prod.produto.precos"
                        label="tipo"
                        v-model="prod.tipo"
                      />
                    </div>
                  </div>
                  <div class="col-2 pt-0" style="font-size: 50px">
                    <b-icon-x
                      v-b-popover.hover.top="'Excluir'"
                      style="cursor: pointer"
                      @click="del(prod)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="prodGroup prodGroupDisabled" @click="addProd()">
              <div class="empty"><b-icon-plus /> adicionar</div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <hr />
          <b-btn variant="primary" :disabled="salvando" @click="salvar">
            <b-icon-save v-if="!salvando" />
            <b-spinner v-if="salvando" small />
            Salvar
          </b-btn>
          <b-btn variant="secondary" @click="cancel"><b-icon-x /> Cancelar</b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import BarraCrud from "../../../components/common/BarraCrud.vue";
import ProdutoComboLib from "../../../libs/ProdutoComboLib";
export default {
  components: {
    BarraCrud,
  },
  props: {},
  mounted() {
    console.log("init", this.$store.state.cardapio);
    this.produtosDisponiveis = this.$store.state.cardapio.reduce((ret, vl) => {
      ret.push(...vl.produtos);
      return ret;
    }, []);
    this.carregar();
  },
  data() {
    return {
      produtosDisponiveis: [],
      objDef: {
        id: null,
        produtos: [],
        nome: "",
        valor: 0,
        validade_i: moment().format("YYYY-MM-DD"),
        validade_f: moment().add(1, "month").format("YYYY-MM-DD"),
      },
      objEdit: this.objDef,
      salvando: false,
      loading: false,
      lista: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    async cancel() {
      await this.onNovo();
      this.$bvModal.hide("modal-edit-combo");
    },
    edit(item) {
      if (item) {
        this.onNovo();
        this.objEdit = item;
      }
    },
    async delCombo(item) {
      this.$swal({
        title: "Tem certeza?",
        text: "Tem certeza que deseja excluir este combo?",
        showCancelButton: true,
        confirmButtonText: 'Excluir!',
        cancelButtonText: 'Não'
      }).then(async x => {
        if (x.isConfirmed) {
          let res = await ProdutoComboLib.delProdutoCombo(item.id);
          if (res && res.success) {
            this.$swal({
              title: "Excluido com sucesso!",
              toast: true,
              timer: 2000,
              icon:'success',
              showConfirmButton: false,
              position: 'top-end'
            });
            this.$store.dispatch('auth/atualizarCardapio')
            this.carregar();
          }
        }
      })
    },
    async salvar() {
      if (!this.objEdit) {
        return;
      }

      let errs = [];
      if (!this.objEdit.validade_i || !this.objEdit.validade_f) {
        errs.push("Informe o periodo de validade do combo!");
      }
      if (!this.objEdit.nome || this.objEdit.nome == "") {
        errs.push("Informe o nome do combo!");
      }
      if (
        !this.objEdit.valor ||
        this.objEdit.valor == "" ||
        this.objEdit.valor == "0,00" ||
        this.objEdit.valor == "R$ 0,00"
      ) {
        errs.push("Informe o valor do combo!");
      }
      if (!this.objEdit.produtos || this.objEdit.produtos.length <= 0) {
        errs.push("Informe os produtos do combo!");
      }

      if (errs.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `Os campos abaixo precisam de atenção <hr/> ${errs.join(
            "<hr />"
          )}`,
          icon: "error",
        });
      } else {
        this.salvando = true;

        try {
          let res = await ProdutoComboLib.storeProdutoCombo(this.objEdit);

          if (res && res.success && res.data.length >= 0) {
            await this.onNovo();
            this.$swal("Combo salvo com sucesso!", "", "success");
            this.$bvModal.hide("modal-edit-combo");
            this.carregar();
            this.$store.dispatch('auth/atualizarCardapio')

          } else {
            this.$swal("Erro ao criar combo!", "", "error");
          }
        } catch (error) {
          console.log("Error on salvar ", error);
        }

        this.salvando = false;
      }
    },
    async carregar() {
      this.loading = true;
      try {
        this.lista = [];
        let res = await ProdutoComboLib.getProdutoCombo();
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.log("error on get combos", error);
      }
      this.loading = false;
    },
    del(prod) {
      this.objEdit.produtos = this.objEdit.produtos.filter(
        (x) => x.id !== prod.id
      );
    },
    addProd() {
      this.objEdit.produtos.push({
        id:
          this.objEdit.produtos && this.objEdit.produtos.length > 0
            ? this.objEdit.produtos.sort((a, b) => b.id - a.id)[0].id + 1
            : 1,
      });
      this.objEdit.produtos = this.objEdit.produtos.sort((a, b) => a.id - b.id);
    },
    onNovo() {
      this.objEdit = Object.assign({}, { ...this.objDef });
      this.$bvModal.show("modal-edit-combo");
    },
    filtrar() {},
  },
};
</script>

<style lang="scss" scoped>
.prodGroup {
  width: 90%;
  margin: 20px;
  border-radius: 20px;
  height: 100px;
  border: 1px solid #eee;
  padding: 20px;

  .empty {
    text-align: center;
    padding-top: 0px;
    font-size: 60px;
    font-weight: bold;
    color: #ccc;
  }
}
.prodGroupDisabled {
  background-color: #eee;
  padding: 0px;
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
}
</style>
